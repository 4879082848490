let focusedElBeforeOpen;
let returnTopLevelEl;





// Create Dialog
function Dialog(dialogEl, overlayEl) {

    this.dialogEl = dialogEl;
    this.overlayEl = overlayEl;

    var focusableEls = this.dialogEl.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');

    this.focusableEls = Array.prototype.slice.call(focusableEls);

    this.firstFocusableEl = this.focusableEls[0];

    this.close(); // Reset
}
// Dialog Open Event
Dialog.prototype.open = function() {

    var Dialog = this;

    document.getElementsByTagName('body')[0].classList.add('open');

    focusedElBeforeOpen = document.activeElement;

    Array.from(document.body.children).forEach(child => {
        if (child !== this.dialogEl) {
            child.inert = true;
        }
    });

    this.dialogEl.removeAttribute('aria-hidden');
    this.overlayEl.removeAttribute('aria-hidden');

    this.dialogEl.addEventListener('keydown', function(e) {
        Dialog._handleKeyDown(e);
    });

    this.overlayEl.addEventListener('click', function() {
        Dialog.close();
    });

    this.firstFocusableEl.focus();
};
// Dialog Close Event
Dialog.prototype.close = function() {

    document.getElementsByTagName('body')[0].classList.remove('open');

    Array.from(document.body.children).forEach(child => {
        if (child !== this.dialogEl) {
            child.inert = false;
        }
    });

    this.dialogEl.setAttribute('aria-hidden', true);
    this.overlayEl.setAttribute('aria-hidden', true);

    if ( focusedElBeforeOpen ) {
        focusedElBeforeOpen.focus();
    }
    resetDialog();
};
// Dialog Key Down Event
Dialog.prototype._handleKeyDown = function(e) {

    var Dialog = this;
    var KEY_ESC = 'Escape';

    switch(e.key) {
        case KEY_ESC:
            Dialog.close();
            break;
        default:
            break;
    }


};
// Dialog Event Listeners
Dialog.prototype.addEventListeners = function(openDialogSel, closeDialogSel) {

    var Dialog = this;

    var openDialogEls = document.querySelectorAll(openDialogSel);
    for ( var i = 0; i < openDialogEls.length; i++ ) {
        openDialogEls[i].addEventListener('click', function() {
            Dialog.open();
        });
    }

    var closeDialogEls = document.querySelectorAll(closeDialogSel);
    for ( var i = 0; i < closeDialogEls.length; i++ ) {
        closeDialogEls[i].addEventListener('click', function() {
            Dialog.close();
        });
    }

};

function resetNavEls(passiveNavEls, subNavEls) {
    for (let i=0; i >= passiveNavEls.length; i++) {
        passiveNavEls[i].setAttribute('aria-hidden', 'false');
        passiveNavEls[i].removeAttribute('tabindex');
    }

    subNavEls.forEach(subNavEl => {
        subNavEl.setAttribute('aria-hidden', 'true');
        subNavEl.setAttribute('tabindex', '-1');
    });
}

function resetDialog() {
    const navDialogEl = document.querySelector('.dialog');
    const passiveNavEls = navDialogEl.querySelectorAll('.top-level');
    const subNavEls = navDialogEl.querySelectorAll('.passive-sub-nav a');
    const globalNavWrapper =  navDialogEl.querySelector('.has-selection');
    const subNavWrapper =  navDialogEl.querySelector('.is-revealed');

    resetNavEls(passiveNavEls, subNavEls);

    if (globalNavWrapper) {
        globalNavWrapper.classList.remove('has-selection');
    }
    if (subNavWrapper) {
        subNavWrapper.classList.remove('is-revealed');
    }
}

function returnTopLevel(subNavWrapper) {
    const passiveNavEls = document.querySelectorAll('.top-level');
    let parentWrapper = subNavWrapper.parentElement.parentElement;
    let subNavEls = subNavWrapper.querySelectorAll('.passive-sub-nav a');

    resetNavEls(passiveNavEls, subNavEls);

    returnTopLevelEl.setAttribute('aria-hidden', 'true');
    returnTopLevelEl.setAttribute('tabindex', '-1');

    parentWrapper.classList.remove('has-selection');
    subNavWrapper.classList.remove('is-revealed');

    passiveNavEls[0].focus();
}

function passiveNavElsHandler(passiveNavEls, parentWrapper) {
    for (let i=0; i >= passiveNavEls.length; i++) {
        passiveNavEls[i].setAttribute('aria-hidden', 'true');
        passiveNavEls[i].setAttribute('tabindex', '-1');
    }
    parentWrapper.classList.add('has-selection');
}

function revealChildNav(targetPassiveElId, parentWrapper) {
    // find matching sub nav ul
    let passiveSubNavEl = document.querySelector(`[data-subnav='${targetPassiveElId}']`);
    // find child elements
    let subNavEls = passiveSubNavEl.querySelectorAll('a.nav-link');

    subNavEls.forEach(subNavEl => {
        subNavEl.setAttribute('aria-hidden', 'false');
        subNavEl.removeAttribute('tabindex')
    });

    returnTopLevelEl.setAttribute('aria-hidden', 'false');
    returnTopLevelEl.removeAttribute('tabindex');

    passiveSubNavEl.classList.add('is-revealed');
    // set focus of first child nav item
    subNavEls[0].focus();
}

function getSeasonalImage(images) {
    let currentTime = new Date();
    let month = currentTime.getMonth() + 1;
    let seasonalImage;
    if(month >= 6 && month <= 8) {
        seasonalImage = images[1];
    } else if(month >= 9 && month <= 11) {
        seasonalImage = images[2];
    } else if(month == 12 || month == 1 || month == 2) {
        seasonalImage = images[3];
    } else {
        seasonalImage = images[0];
    }
    return seasonalImage;
}

function create_cookie(name, value, days2expire, path) {
    let date = new Date();
    date.setTime(date.getTime() + (days2expire * 24 * 60 * 60 * 1000));
    var expires = date.toUTCString();
    document.cookie = name + '=' + value + ';' + 'expires=' + expires + ';' + 'path=' + path + ';';
}

function retrieve_cookie(name) {
    var cookie_value = "",
        current_cookie = "",
        name_expr = name + "=",
        all_cookies = document.cookie.split(';'),
        n = all_cookies.length;

    for(var i = 0; i < n; i++) {
        current_cookie = all_cookies[i].trim();
        if(current_cookie.indexOf(name_expr) == 0) {
            cookie_value = current_cookie.substring(name_expr.length, current_cookie.length);
            break;
        }
    }
    return cookie_value;
}

document.addEventListener('DOMContentLoaded', function(){
    console.log('Hi. 👋');

    // global search
    const globalSearchEl = document.querySelector('.global-search');
    const searchOpenEl = document.querySelector('.open-search');
    const searchCloseEl = document.querySelector('.close-search');

    searchOpenEl.addEventListener('click', function(){
       if (globalSearchEl) {
           searchOpenEl.setAttribute('aria-expanded', 'true');
           searchOpenEl.setAttribute('tabindex','-1');
           globalSearchEl.classList.add('open');
           document.querySelector('.search-form-input').focus();
       }
    });

    if (searchCloseEl) {
        searchCloseEl.addEventListener('click', function(){
            if (globalSearchEl) {
                searchOpenEl.setAttribute('aria-expanded', 'false')
                searchOpenEl.removeAttribute('tabindex');
                globalSearchEl.classList.remove('open');
            }
        });
    }

    // global nav dialog
    const navDialogEl = document.querySelector('.dialog');
    const dialogOverlay = document.querySelector('.dialog-overlay');
    const passiveNavEls = document.querySelectorAll('.passive');
    let myDialog = new Dialog(navDialogEl, dialogOverlay);
    myDialog.addEventListeners('.open-dialog', '.close-dialog');

    if (passiveNavEls) {
        passiveNavEls.forEach(passiveNavEl => {
            passiveNavEl.addEventListener('click', (e) => {
                let targetPassiveNavEl = e.target;
                let targetPassiveElId = targetPassiveNavEl.getAttribute('id');
                let parentWrapper = passiveNavEl.parentElement.parentElement;
                returnTopLevelEl = parentWrapper.querySelector(`#return-${targetPassiveElId}`);
                passiveNavElsHandler(passiveNavEls, parentWrapper);
                revealChildNav(targetPassiveElId, parentWrapper);

                if (returnTopLevelEl) {
                    returnTopLevelEl.addEventListener('click', (e) => {
                        let subNavWrapper = returnTopLevelEl.parentElement.parentElement;
                        returnTopLevel(subNavWrapper);
                    });
                }
            });
        });
    }

    // homepage seasonal images
    if (window.heroSeasonalImages) {
        let heroImages = JSON.parse(window.heroSeasonalImages);
        let heroSeasonalBgImage = getSeasonalImage(heroImages);
        const heroImgObjectFitEl =  document.querySelector('.hero-img-wrapper .object-fit-wrapper');
        if (heroSeasonalBgImage && heroImgObjectFitEl) {
            const imgEl = document.createElement('img');
            imgEl.classList.add('object-fit-img');
            imgEl.setAttribute('src', heroSeasonalBgImage);
            heroImgObjectFitEl.appendChild(imgEl);
        }
    }
    if (window.footerSeasonalImages) {
        let footerImages = JSON.parse(window.footerSeasonalImages);
        let footerSeasonalBgImage = getSeasonalImage(footerImages);
        const footerImgObjectFitEl =  document.querySelector('.footer-signup');
        if (footerSeasonalBgImage && footerImgObjectFitEl) {
            footerImgObjectFitEl.style.backgroundImage = `url('${footerSeasonalBgImage}')`;
        }
        // const footerImgObjectFitEl =  document.querySelector('.footer-signup .object-fit-wrapper');
        // if (footerSeasonalBgImage && footerImgObjectFitEl) {
        //     const imgEl = document.createElement('img');
        //     imgEl.classList.add('object-fit-img');
        //     imgEl.setAttribute('src', footerSeasonalBgImage);
        //     footerImgObjectFitEl.appendChild(imgEl);
        // }
    }

    // global site alert
    const globalAlert = document.querySelector('#global-alert');
    const alertDate = window.alertUpdated;
    let globalAlertClosed = retrieve_cookie(`global_alert_closed_${alertDate}`);

    if (globalAlertClosed && globalAlert){
        globalAlert.style.display = 'none';
        globalAlert.setAttribute('aria-hidden', 'true');
    }
    if (globalAlert) {
        globalAlert.addEventListener('closed.bs.alert', event => {
            create_cookie(`global_alert_closed_${alertDate}`, 'dismissed_alert_true', 1, "/");
        });
    }



        var links = document.getElementsByTagName("a");
        var currentHost = window.location.hostname;

        for (var i = 0; i < links.length; i++) {
            links[i].addEventListener("click", function(event) {
                var linkHost = this.hostname;
                if (linkHost && linkHost !== currentHost) {
                    event.preventDefault();
                    var linkUrl = this.href;
                    document.getElementById("externalLinkModal").style.display = "block";
                    document.getElementById("continue").onclick = function() {
                        // window.location.href = linkUrl;
                        window.open(
                            linkUrl,
                            '_blank' // <- This is what makes it open in a new window.
                        );
                        document.getElementById("externalLinkModal").style.display = "none";
                    };
                    document.getElementById("cancel").onclick = function() {
                        document.getElementById("externalLinkModal").style.display = "none";
                    };
                }
            });
        }






});